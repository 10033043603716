import React, { Suspense, useEffect } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

// import CIcon from '@coreui/icons-react';

import Content from "./Content";
import { logoutAction } from "../../Store/Actions/authAction";
import { setUserProfileToNullAction } from "../../Store/Actions/userAction";
// import AvatarDefault from "./../../Assets/avatar_default.png";

// import BodyClassName from 'react-body-classname';
// import LogoApp from './../../Assets/CRUDTemplate_Logo_Short.png'
// import LogoAppMain from './../../Assets/CRUDTemplate_Logo.png'
import './BackendLayout.sass'

import classNames from 'classnames'
import {
	TheSidebar,
	TheAside,
	TheFooter,
	TheHeader
} from './containers';

import Loading from '../../Components/Loading'

import { CContainer, CFade } from '@coreui/react'

const APP_NAME = process.env.REACT_APP_NAME || 'GolfTec'

const BackendLayout = (props) => {
	// const { profile } = props;

	const darkMode = props.theme.darkMode;
	const classes = classNames(
		'c-app c-default-layout',
		darkMode && 'c-dark-theme'
	)

	useEffect(() => {
		document.title = `${get(props, 'routeName')} - ${APP_NAME}`
	}, [])


	return (
		<div className={classes}>
			<TheSidebar {...props} />
			<TheAside {...props} />
			<div className="c-wrapper">
				<TheHeader {...props} />
				<div className="c-body">
					<main className="c-main">
						<CContainer fluid={get(props, 'layoutConfig.fluid', false)}>
							<CFade>
								<Suspense fallback={<Loading />}>
									<Content children={props.children} />
								</Suspense>
							</CFade>
						</CContainer>
					</main>
				</div>
				<TheFooter />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	// state: state,
	isAuthenticated: state.authentication.isAuthenticated,
	// 
	profile: state.user.profile,
	theme: state.themeReducer
});

const mapDispatchToProps = (dispatch) => ({
	logout: () => {
		dispatch(logoutAction());
	},
	setUserProfileToNull: () => {
		dispatch(setUserProfileToNullAction());
	},
	setTheme: data => {
		dispatch(data)
	}
});

const withCompose = compose(
	connect(mapStateToProps, mapDispatchToProps)
);

BackendLayout.propTypes = {
	isAuthenticated: PropTypes.bool,
	classes: PropTypes.object,
	// 
	profile: PropTypes.object,

	theme: PropTypes.object,
	// 
	logout: PropTypes.func,
	setUserProfileToNull: PropTypes.func,
};

export default withCompose(BackendLayout);
