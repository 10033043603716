import { lazy } from "react";

import { ROLES } from "../Utils/roleAndPermUtils";
import * as URLS from "../Utils/routerConstants";

import lessonManageRouters from "../Backend/Pages/LessonManage/routers"
import myStudentsRouters from "../Backend/Pages/MyStudents/routers"
import lessonPostRouters from "../Backend/Pages/LessonPost/routers"
import exerciseLibraryRouters from "../Backend/Pages/ExerciseLibrary/routers"
import sevenStageSwingLibraryRouters from "../Backend/Pages/SevenStageSwingLibrary/routers"
import golfSwingRouters from "../Backend/Pages/GolfSwing/routers"
import reportsRouters from "../Backend/Pages/Reports/routers"

import imagesLibraryRouters from "../Backend/Pages/ImageLibrary/routers"
import videosLibraryRouters from "../Backend/Pages/VideosLibrary/routers"

const BackendLayout = lazy(() => import('../Backend/Layout/BackendLayout'));
const DashboardPage = lazy(() => import('../Backend/Pages/Dashboard'));
const ProfilePage = lazy(() => import('../Backend/Pages/Profile/ProfilePage'));
const ChangePasswordPage = lazy(() => import('../Backend/Pages/ChangePassword'));

const ReportsPage = lazy(() => import('../Backend/Pages/Statistic/Reports/ReportsPage'));
const ChartsPage = lazy(() => import('../Backend/Pages/Statistic/Charts/ChartsPage'));
const NotAuthorizationPage = lazy(() => import('../Backend/Pages/NotAuthorization/NotAuthorizationPage'));
const MyPermissionPage = lazy(() => import('../Backend/Pages/MyPermission/MyPermissionPage'));
const MyGroupPage = lazy(() => import('../Backend/Pages/MyGroup/MyGroupPage'));
const NotFoundPage = lazy(() => import('../Backend/Pages/NotFoundPage/NotFoundPage'));

// payment 
const PaymentHistoryPage = lazy(() => import('../Backend/Pages/PaymentHistory'));
const PaymentOptionsPage = lazy(() => import('../Backend/Pages/PaymentOptions'));
const BillingAndPaymentPage = lazy(() => import('../Backend/Pages/BillingAndPayment'));

// video
const VideoCategoriesPage = lazy(() => import('../Backend/Pages/Video'));
const VideoListPage = lazy(() => import('../Backend/Pages/Video/VideoList'));
// laulv
const BackendLayout_VideoCourses = lazy(() => import('../Backend/Layout/BackendLayout_VideoCourses'));
const VideoCoursesPage = lazy(() => import('../Backend/Pages/VideoCourses'));
const VideoCourseDetailPage = lazy(() => import('../Backend/Pages/VideoCourses/VideoCourseDetailPage'));
const PaymentHistoryVideoCourse = lazy(() => import('../Backend/Pages/VideoCourses/PaymentHistoryVideoCourses'));
const PaymentHistoryForCoachPage = lazy(() => import('../Backend/Pages/VideoCourses/PaymentHistoryForCoachPage'));
const VideoCourseDetailPage1 = lazy(() => import('../Backend/Pages/VideoCourses/VideoCourseDetailPage1'));
// end laulv

const routers = [
  // Library Route (coach only)
  ...imagesLibraryRouters,
  ...videosLibraryRouters,

  // Lesson manage Route (coach only)
  ...lessonManageRouters,
  ...myStudentsRouters,
  ...lessonPostRouters,
  ...exerciseLibraryRouters,
  ...sevenStageSwingLibraryRouters,

  // Golf swings
  ...golfSwingRouters,

  // Reports
  ...reportsRouters,
  
  {
    path: URLS.DASHBOARD_URL,
    name: "Dashboard",
    component: DashboardPage,
    layout: BackendLayout,
    layoutConfig: {
      fluid: true,
    }
  },


  // Video for member only
  {
    path: `${URLS.VIDEO_CATEGORIES_URL}/:slug/:videoSlug?`,
    name: "Video Detail",
    component: VideoListPage,
    layout: BackendLayout,
    // allows: [ROLES.MEMBER],
  },
  {
    path: URLS.VIDEO_CATEGORIES_URL,
    name: "Golf Video Library",
    component: VideoCategoriesPage,
    layout: BackendLayout,
    // allows: [ROLES.MEMBER],
  },
  // laulv
  {
    path: URLS.VIDEO_COURSE_CATEGORIES_URL,
    name: "Golf Video Courses",
    component: VideoCoursesPage,
    layout: BackendLayout_VideoCourses,
    allows: [ROLES.MEMBER, ROLES.COACH],
  },
  {
    path: `${URLS.VIDEO_COURSE_CATEGORIES_DETAIL_URL}/:slug`,
    name: "Video course Detail",
    component: VideoCourseDetailPage1,
    layout: BackendLayout_VideoCourses,
    allows: [ROLES.MEMBER, ROLES.COACH],
  },
  {
    path: `${URLS.VIDEO_COURSE_CATEGORIES_DETAIL_1_URL}/:slug`,
    name: "Video course Detail 1",
    component: VideoCourseDetailPage1,
    layout: BackendLayout_VideoCourses,
    allows: [ROLES.MEMBER, ROLES.COACH],
  },
  {
    path: URLS.PAYMENT_HISTORY_VIDEO_COURSE_URL,
    name: "Payment History",
    component: PaymentHistoryVideoCourse,
    layout: BackendLayout_VideoCourses,
    allows: [ROLES.MEMBER, ROLES.COACH],
  },
  {
    path: URLS.PAYMENT_HISTORY_FOR_COACH_URL,
    name: "Payment History",
    component: PaymentHistoryForCoachPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  // end laulv

  // =============== My Account ==============
  {
    path: URLS.PROFILE_URL,
    name: "Profile",
    component: ProfilePage,
    layout: BackendLayout,
  },
  {
    path: URLS.CHANGE_PASSWORD_URL,
    name: "Change Password",
    component: ChangePasswordPage,
    layout: BackendLayout,
  },
  // payment for member only
  {
    path: URLS.PAYMENT_HISTORY_URL,
    name: "Payment History",
    component: PaymentHistoryPage,
    layout: BackendLayout,
    allows: [ROLES.MEMBER],
  },
  {
    path: URLS.PAYMENT_OPTIONS_URL,
    name: "Payment Options",
    component: PaymentOptionsPage,
    layout: BackendLayout,
    allows: [ROLES.MEMBER],
  },
  {
    path: URLS.BILLING_PAYMENT_URL,
    name: "Billing And Payment",
    component: BillingAndPaymentPage,
    layout: BackendLayout,
    allows: [ROLES.MEMBER],
  },


  // user
  {
    path: "/my-group",
    name: "My Group",
    component: MyGroupPage,
    layout: BackendLayout,
  },
  {
    path: "/my-permission",
    name: "My Permission",
    component: MyPermissionPage,
    layout: BackendLayout,
  },
  {
    path: "/reports",
    name: "Report",
    component: ReportsPage,
    layout: BackendLayout,
    allows: [ROLES.MEMBER, ROLES.COACH],
  },
  {
    path: "/charts",
    name: "Charts",
    component: ChartsPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: URLS.NO_PERMISSION_URL,
    component: NotAuthorizationPage,
    layout: BackendLayout,
  },
  {
    component: NotFoundPage,
    layout: BackendLayout,
  },
];

export default routers;