import React from 'react'
import { Link } from 'react-router-dom'
import { CFooter } from '@coreui/react'

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <div>
        {/* <a href="https://coreui.io" target="_blank" rel="noopener noreferrer">CoreUI</a> */}
        {/* <span className="ml-1">&copy; 2020 creativeLabs.</span> */}
      </div>
      <div className="mfs-auto">
        <span className="mr-1">&copy; 2021 </span>
        <Link to="https://www.golftec.com.au/">GolfTec Australia Pty Ltd</Link>
        <span className="mr-1">, All rights reserved.</span>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter)
