// user - profile

export const AT_CHANGE_PASSWORD_REQUEST = "AT_CHANGE_PASSWORD_REQUEST";
export const AT_CHANGE_PASSWORD_SUCCESS = "AT_CHANGE_PASSWORD_SUCCESS";
export const AT_CHANGE_PASSWORD_FAILURE = "AT_CHANGE_PASSWORD_FAILURE";

export const AT_UPDATE_PROFILE_REQUEST = "AT_UPDATE_PROFILE_REQUEST";
export const AT_UPDATE_PROFILE_SUCCESS = "AT_UPDATE_PROFILE_SUCCESS";
export const AT_UPDATE_PROFILE_FAILURE = "AT_UPDATE_PROFILE_FAILURE";

export const AT_USER_GET_PROFILE_REQUEST = "AT_USER_GET_PROFILE_REQUEST";
export const AT_USER_GET_PROFILE_SUCCESS = "AT_USER_GET_PROFILE_SUCCESS";
export const AT_USER_GET_PROFILE_FAILURE = "AT_USER_GET_PROFILE_FAILURE";
export const AT_USER_SET_PROFILE_NULL = "AT_USER_SET_PROFILE_NULL";

export const AT_GET_USER_DASHBOARD_REQUEST = "AT_GET_USER_DASHBOARD_REQUEST";
export const AT_GET_USER_DASHBOARD_SUCCESS = "AT_GET_USER_DASHBOARD_SUCCESS";
export const AT_GET_USER_DASHBOARD_FAILURE = "AT_GET_USER_DASHBOARD_FAILURE";

// authencation

export const AT_AUTH_RESET_PASSWORD_REQUEST = "AT_AUTH_RESET_PASSWORD_REQUEST";
export const AT_AUTH_RESET_PASSWORD_SUCCESS = "AT_AUTH_RESET_PASSWORD_SUCCESS";
export const AT_AUTH_RESET_PASSWORD_FAILURE = "AT_AUTH_RESET_PASSWORD_FAILURE";

export const AT_AUTH_ACTIVE_ACCOUNT_REQUEST = "AT_AUTH_ACTIVE_ACCOUNT_REQUEST";
export const AT_AUTH_ACTIVE_ACCOUNT_SUCCESS = "AT_AUTH_ACTIVE_ACCOUNT_SUCCESS";
export const AT_AUTH_ACTIVE_ACCOUNT_FAILURE = "AT_AUTH_ACTIVE_ACCOUNT_FAILURE";

export const AT_AUTH_RESEND_LINK_ACTIVATION_REQUEST = "AT_AUTH_RESEND_LINK_ACTIVATION_REQUEST";
export const AT_AUTH_RESEND_LINK_ACTIVATION_SUCCESS = "AT_AUTH_RESEND_LINK_ACTIVATION_SUCCESS";
export const AT_AUTH_RESEND_LINK_ACTIVATION_FAILURE = "AT_AUTH_RESEND_LINK_ACTIVATION_FAILURE";

export const AT_AUTH_FORGOT_PASSWORD_REQUEST = "AT_AUTH_FORGOT_PASSWORD_REQUEST";
export const AT_AUTH_FORGOT_PASSWORD_SUCCESS = "AT_AUTH_FORGOT_PASSWORD_SUCCESS";
export const AT_AUTH_FORGOT_PASSWORD_FAILURE = "AT_AUTH_FORGOT_PASSWORD_FAILURE";

export const AT_AUTH_SIGNUP_REQUEST = "AT_AUTH_SIGNUP_REQUEST";
export const AT_AUTH_SIGNUP_SUCCESS = "AT_AUTH_SIGNUP_SUCCESS";
export const AT_AUTH_SIGNUP_FAILURE = "AT_AUTH_SIGNUP_FAILURE";

export const AT_AUTH_LOGIN_REQUEST = "AT_AUTH_LOGIN_REQUEST";
export const AT_AUTH_LOGIN_SUCCESS = "AT_AUTH_LOGIN_SUCCESS";
export const AT_AUTH_LOGIN_FAILURE = "AT_AUTH_LOGIN_FAILURE";

export const AT_AUTH_REDIRECT_TO_LOGIN_PAGE = "AT_AUTH_REDIRECT_TO_LOGIN_PAGE";
export const AT_AUTH_LOGOUT = "AT_AUTH_LOGOUT";

export const AT_HELLO = "AT_HELLO";


// =============
// Payment 
// =============
export const AT_GET_PAYMENT_LOG_REQUEST = "AT_GET_PAYMENT_LOG_REQUEST";
export const AT_GET_PAYMENT_LOG_SUCCESS = "AT_GET_PAYMENT_LOG_SUCCESS";
export const AT_GET_PAYMENT_LOG_FAILURE = "AT_GET_PAYMENT_LOG_FAILURE";

// practice membership
export const AT_GET_PRACTICE_MEMBERSHIP_REQUEST = "AT_GET_PRACTICE_MEMBERSHIP_REQUEST";
export const AT_GET_PRACTICE_MEMBERSHIP_SUCCESS = "AT_GET_PRACTICE_MEMBERSHIP_SUCCESS";
export const AT_GET_PRACTICE_MEMBERSHIP_FAILURE = "AT_GET_PRACTICE_MEMBERSHIP_FAILURE";

// Lesson membership
export const AT_GET_LESSON_MEMBERSHIP_REQUEST = "AT_GET_LESSON_MEMBERSHIP_REQUEST";
export const AT_GET_LESSON_MEMBERSHIP_SUCCESS = "AT_GET_LESSON_MEMBERSHIP_SUCCESS";
export const AT_GET_LESSON_MEMBERSHIP_FAILURE = "AT_GET_LESSON_MEMBERSHIP_FAILURE";

// =============
// Video 
// =============

// get video category
export const AT_GET_VIDEO_CATEGORY_REQUEST = "AT_GET_VIDEO_CATEGORY_REQUEST";
export const AT_GET_VIDEO_CATEGORY_SUCCESS = "AT_GET_VIDEO_CATEGORY_SUCCESS";
export const AT_GET_VIDEO_CATEGORY_FAILURE = "AT_GET_VIDEO_CATEGORY_FAILURE";

// get video category detail
export const AT_GET_VIDEO_CATEGORY_DETAIL_REQUEST = "AT_GET_VIDEO_CATEGORY_DETAIL_REQUEST";
export const AT_GET_VIDEO_CATEGORY_DETAIL_SUCCESS = "AT_GET_VIDEO_CATEGORY_DETAIL_SUCCESS";
export const AT_GET_VIDEO_CATEGORY_DETAIL_FAILURE = "AT_GET_VIDEO_CATEGORY_DETAIL_FAILURE";

// get video detail
export const AT_GET_VIDEO_DETAIL_REQUEST = "AT_GET_VIDEO_DETAIL_REQUEST";
export const AT_GET_VIDEO_DETAIL_SUCCESS = "AT_GET_VIDEO_DETAIL_SUCCESS";
export const AT_GET_VIDEO_DETAIL_FAILURE = "AT_GET_VIDEO_DETAIL_FAILURE";

// get video search
export const AT_GET_VIDEO_SEARCH_REQUEST = "AT_GET_VIDEO_SEARCH_REQUEST";
export const AT_GET_VIDEO_SEARCH_SUCCESS = "AT_GET_VIDEO_SEARCH_SUCCESS";
export const AT_GET_VIDEO_SEARCH_FAILURE = "AT_GET_VIDEO_SEARCH_FAILURE";



// =============
// Library 
// =============

// get image library
export const AT_GET_IMAGE_LIBRARY_REQUEST = "AT_GET_IMAGE_LIBRARY_REQUEST";
export const AT_GET_IMAGE_LIBRARY_SUCCESS = "AT_GET_IMAGE_LIBRARY_SUCCESS";
export const AT_GET_IMAGE_LIBRARY_FAILURE = "AT_GET_IMAGE_LIBRARY_FAILURE";
// get all video library
export const AT_GET_ALL_VIDEO_LIBRARY_REQUEST = "AT_GET_ALL_VIDEO_LIBRARY_REQUEST";
export const AT_GET_ALL_VIDEO_LIBRARY_SUCCESS = "AT_GET_ALL_VIDEO_LIBRARY_SUCCESS";
export const AT_GET_ALL_VIDEO_LIBRARY_FAILURE = "AT_GET_ALL_VIDEO_LIBRARY_FAILURE";



// =============
// Lesson 
// =============

// get all lesson category
export const AT_GET_ALL_LESSON_CATEGORY_REQUEST = "AT_GET_ALL_LESSON_CATEGORY_REQUEST";
export const AT_GET_ALL_LESSON_CATEGORY_SUCCESS = "AT_GET_ALL_LESSON_CATEGORY_SUCCESS";
export const AT_GET_ALL_LESSON_CATEGORY_FAILURE = "AT_GET_ALL_LESSON_CATEGORY_FAILURE";
// get lesson
export const AT_GET_LESSON_REQUEST = "AT_GET_LESSON_REQUEST";
export const AT_GET_LESSON_SUCCESS = "AT_GET_LESSON_SUCCESS";
export const AT_GET_LESSON_FAILURE = "AT_GET_LESSON_FAILURE";
// get lesson post
export const AT_GET_LESSON_POST_REQUEST = "AT_GET_LESSON_POST_REQUEST";
export const AT_GET_LESSON_POST_SUCCESS = "AT_GET_LESSON_POST_SUCCESS";
export const AT_GET_LESSON_POST_FAILURE = "AT_GET_LESSON_POST_FAILURE";


// =============
// ExcerciseLibrary 
// =============

// get ExcerciseLibrary
export const AT_GET_EXERCISE_LIBRARY_REQUEST = "AT_GET_EXERCISE_LIBRARY_REQUEST";
export const AT_GET_EXERCISE_LIBRARY_SUCCESS = "AT_GET_EXERCISE_LIBRARY_SUCCESS";
export const AT_GET_EXERCISE_LIBRARY_FAILURE = "AT_GET_EXERCISE_LIBRARY_FAILURE";

// =============
// SevenStageSwingLibrary 
// =============

// get SevenStageSwingLibrary category
export const AT_GET_SEVEN_STAGE_SWING_LIBRARY_CATEGORY_REQUEST = "AT_GET_SEVEN_STAGE_SWING_LIBRARY_CATEGORY_REQUEST";
export const AT_GET_SEVEN_STAGE_SWING_LIBRARY_CATEGORY_SUCCESS = "AT_GET_SEVEN_STAGE_SWING_LIBRARY_CATEGORY_SUCCESS";
export const AT_GET_SEVEN_STAGE_SWING_LIBRARY_CATEGORY_FAILURE = "AT_GET_SEVEN_STAGE_SWING_LIBRARY_CATEGORY_FAILURE";

// get SevenStageSwingLibrary list
export const AT_GET_SEVEN_STAGE_SWING_LIBRARY_REQUEST = "AT_GET_SEVEN_STAGE_SWING_LIBRARY_REQUEST";
export const AT_GET_SEVEN_STAGE_SWING_LIBRARY_SUCCESS = "AT_GET_SEVEN_STAGE_SWING_LIBRARY_SUCCESS";
export const AT_GET_SEVEN_STAGE_SWING_LIBRARY_FAILURE = "AT_GET_SEVEN_STAGE_SWING_LIBRARY_FAILURE";


// =============
// StudentsOfCoach 
// =============

// get StudentsOfCoach list
export const AT_GET_STUDENTS_OF_COACH_REQUEST = "AT_GET_STUDENTS_OF_COACH_REQUEST";
export const AT_GET_STUDENTS_OF_COACH_SUCCESS = "AT_GET_STUDENTS_OF_COACH_SUCCESS";
export const AT_GET_STUDENTS_OF_COACH_FAILURE = "AT_GET_STUDENTS_OF_COACH_FAILURE";

// get StudentsOfCoach detail
export const AT_GET_STUDENTS_OF_COACH_DETAIL_REQUEST = "AT_GET_STUDENTS_OF_COACH_DETAIL_REQUEST";
export const AT_GET_STUDENTS_OF_COACH_DETAIL_SUCCESS = "AT_GET_STUDENTS_OF_COACH_DETAIL_SUCCESS";
export const AT_GET_STUDENTS_OF_COACH_DETAIL_FAILURE = "AT_GET_STUDENTS_OF_COACH_DETAIL_FAILURE";

// get All StudentsOfCoach list
export const AT_GET_ALL_STUDENTS_OF_COACH_REQUEST = "AT_GET_ALL_STUDENTS_OF_COACH_REQUEST";
export const AT_GET_ALL_STUDENTS_OF_COACH_SUCCESS = "AT_GET_ALL_STUDENTS_OF_COACH_SUCCESS";
export const AT_GET_ALL_STUDENTS_OF_COACH_FAILURE = "AT_GET_ALL_STUDENTS_OF_COACH_FAILURE";

// get LessonOfStudent list
export const AT_GET_LESSON_OF_STUDENT_REQUEST = "AT_GET_LESSON_OF_STUDENT_REQUEST";
export const AT_GET_LESSON_OF_STUDENT_SUCCESS = "AT_GET_LESSON_OF_STUDENT_SUCCESS";
export const AT_GET_LESSON_OF_STUDENT_FAILURE = "AT_GET_LESSON_OF_STUDENT_FAILURE";

// get all students list
export const AT_GET_ALL_STUDENTS_REQUEST = "AT_GET_ALL_STUDENTS_REQUEST";
export const AT_GET_ALL_STUDENTS_SUCCESS = "AT_GET_ALL_STUDENTS_SUCCESS";
export const AT_GET_ALL_STUDENTS_FAILURE = "AT_GET_ALL_STUDENTS_FAILURE";

// =============
// Golf Swing 
// =============

// get golf swing list
export const AT_GET_GOLF_SWING_REQUEST = "AT_GET_GOLF_SWING_REQUEST";
export const AT_GET_GOLF_SWING_SUCCESS = "AT_GET_GOLF_SWING_SUCCESS";
export const AT_GET_GOLF_SWING_FAILURE = "AT_GET_GOLF_SWING_FAILURE";

// get token by user id list
export const AT_GET_GOLF_SWING_TOKEN_BY_USER_REQUEST = "AT_GET_GOLF_SWING_TOKEN_BY_USER_REQUEST";
export const AT_GET_GOLF_SWING_TOKEN_BY_USER_SUCCESS = "AT_GET_GOLF_SWING_TOKEN_BY_USER_SUCCESS";
export const AT_GET_GOLF_SWING_TOKEN_BY_USER_FAILURE = "AT_GET_GOLF_SWING_TOKEN_BY_USER_FAILURE";

// get all golf swing list
export const AT_GET_GOLF_SWING_GET_ALL_REQUEST = "AT_GET_GOLF_SWING_GET_ALL_REQUEST";
export const AT_GET_GOLF_SWING_GET_ALL_SUCCESS = "AT_GET_GOLF_SWING_GET_ALL_SUCCESS";
export const AT_GET_GOLF_SWING_GET_ALL_FAILURE = "AT_GET_GOLF_SWING_GET_ALL_FAILURE";

// GLOBAL ACTION
export const AT_GLOBAL_REQUEST = "AT_GLOBAL_REQUEST";
export const AT_GLOBAL_SUCCESS = "AT_GLOBAL_SUCCESS";
export const AT_GLOBAL_FAILURE = "AT_GLOBAL_FAILURE";

// focus_zone LIST
export const AT_GET_FOCUS_ZONES_LIST = "AT_GET_FOCUS_ZONES_LIST";

// focus_zone library LIST
export const AT_GET_FOCUS_ZONES_LIBRARY_LIST = "AT_GET_FOCUS_ZONES_LIBRARY_LIST";

// focus_zone video library LIST
export const AT_GET_FOCUS_ZONES_VIDEO_LIBRARY_LIST = "AT_GET_FOCUS_ZONES_VIDEO_LIBRARY_LIST";
