// export const URL_SITE = "http://127.0.0.1:5001"; 
export const URL_SITE = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000/api/";

// User Profile
export const API_CHANGE_PASSWORD_POST = URL_SITE + "account/change-password/";
export const API_UPDATE_PROFILE_POST = URL_SITE + "account/update-user-profile/";
export const API_USER_PROFILE_GET = URL_SITE + "account/get-user-profile/";
export const API_USER_DASHBOARD_GET = URL_SITE + "get-user-dashboard/";

// Auth
export const API_RESET_PASSWORD_POST = URL_SITE + "auth/reset-password/";
export const API_ACTIVE_ACCOUNT_POST = URL_SITE + "auth/active-account/";
export const API_RESEND_LINK_ACTIVATION_POST = URL_SITE + "auth/resend-link-active/";
export const API_FORGOT_PASSWORD_POST = URL_SITE + "auth/forgot-password/";
export const API_SIGNUP_POST = URL_SITE + "auth/register/";
export const API_SIGNUP_GET = URL_SITE + "auth/register/get-invite-info/";
export const API_LOGIN_POST = URL_SITE + "auth/login/";

export const API_GET_COUNTRY_LIST = URL_SITE + "get-country-list/";

// payment
export const API_GET_PAYMENT_LOG_LIST = URL_SITE + "payments/payment-logs/";
export const API_GET_PRACTICE_MEMBERSHIP_LIST = URL_SITE + "payments/get-practice-membership/";
export const API_GET_LESSON_MEMBERSHIP_LIST = URL_SITE + "payments/get-lesson-membership/";
export const API_CREATE_PAYMENT_LOG = URL_SITE + "payments/payment-logs/";

// video category
export const API_GET_VIDEO_CATEGORY = URL_SITE + "categories/";
// video
export const API_VIDEO = URL_SITE + "videos/";

export const API_VIDEO_DETAILS = URL_SITE + "video/";

export const API_VIDEO_LIBRARY_CK_EDITOR_UPLOAD = URL_SITE + "video-library/ck-editor-upload/";

// image library
export const API_GET_IMAGE_LIBRARY = URL_SITE + "image-library/";

// Lesson
export const API_LESSON = URL_SITE + "lesson/";

// Excercise Library
export const API_EXERCISE_LIBRARY = URL_SITE + "exercise-library/";

// Seven Stage Swing Library
export const API_SEVEN_STAGE_SWING_LIBRARY = URL_SITE + "seven-stage-swing-library/";


// students-of-coach
export const API_STUDENTS_OF_COACH = URL_SITE + "students-of-coach/";
export const API_STUDENTS_OF_COACH__ALL_STUDENTS = API_STUDENTS_OF_COACH + "get-all-students/";
export const API_STUDENTS_OF_COACH__ALL_COACHS = API_STUDENTS_OF_COACH + "get-all-coachs/";
export const API_STUDENTS_OF_COACH__GET_COACH = API_STUDENTS_OF_COACH + "get-coach/";
export const API_LESSON_OF_STUDENT = URL_SITE + "lessons-of-student/";

// get all library by category
export const API_GET_ALL_LIBRARY_BY_CATEGORY = URL_SITE + "get-all-library-by-category/";

// get all library by category
export const API_GET_GOLF_SWING_USER = URL_SITE + "golf-swings/";

// stripe
export const API_STRIPE = URL_SITE + "stripe/";
export const API_STRIPE_CREATE_SETUP_INTENT = API_STRIPE + "create-setup-intent/";
export const API_STRIPE_PACKAGE_PURCHASE = API_STRIPE + "purchase/";
export const API_STRIPE_CREATE_SESSION = API_STRIPE + "create-session/";
export const API_STRIPE_DELETE_PAYPEMT_METHOD = API_STRIPE + "detach-payment-method/";
export const API_STRIPE_UPDATE_DEFAULT_PAYPEMT_METHOD = API_STRIPE + "update-default-payment-method/";
export const API_STRIPE_UPDATE_DEFAULT_PAYPEMT_METHOD_BY_SESSION_ID = API_STRIPE + "update-default-payment-method-by-session-id/";

// REPORTS
export const API_REPORTS = URL_SITE + "reports/";
export const API_REPORTS__NUMBER_OF_LESSONS_GIVEN = API_REPORTS + "number-of-lessons-given/";
export const API_REPORTS__LESSON_OF_COACH = API_REPORTS + "lesson-of-coach/";
export const API_REPORTS__REVENUE_BROKEN_DOWN_BY_PRODUCT = API_REPORTS + "revenue-broken-down-by-product/";
export const API_REPORTS__USERS_CANCELLED_SUBSCRIPTION = API_REPORTS + "users-canceled-subscription/";
export const API_REPORTS__PAYMENT_LOGS = API_REPORTS + "payment-logs/";
export const API_REPORTS__AT_RISK_REPORT = API_REPORTS + "at-risk-report/";
export const API_REPORTS__PAGE_VIEW = API_REPORTS + "pageview/";
export const API_REPORTS__DASH_BOARD = API_REPORTS + "dashboard/";
export const API_REPORTS__MEMBERS = API_REPORTS + "members/";
export const API_REPORTS__MEMBERS__TYPES = {
  active: 'active',
  expired: 'expired',
  trial: 'trial',
};
export const API_REPORTS__CREATE_PAGEVIEW = URL_SITE + "pageview/";

export const API_REPORTS__DASH_BOARD__TYPE_URL = {
  coach_performance: 'coach-performance',
  membership_purchases: 'membership-purchases',
  new_lessons: 'new-lessons',
  new_students: 'new-students',
  token_purchases: 'token-purchases',
};


// laulv
export const API_GET_ALL_VIDEO_COURSE_CATEGORY = URL_SITE + "category-video-courses/";
export const API_GET_ALL_VIDEO_COURSE_BY_CATEGORY = URL_SITE + "video-courses/";
export const API_VIDEO_COURSE_GET_PAYMENT_LOG = URL_SITE + "payments-course/payment-logs/";
export const API_VIDEO_COURSE_PRICE_GET_INFO = URL_SITE + "payments-course/video-course-price/";
export const API_VIDEO_COURSE_PAYMENT_LOG_CREATE = URL_SITE + "payments-course/payment-logs/";
export const API_VIDEO_COURSE_GET_ALL_TRACKING_VIDEO_BY_CATEGORY = URL_SITE + "list-all-tracking-video-by-category-api/";
export const API_VIDEO_COURSE_POST_TRACKING_VIDEO = URL_SITE + "add-track-view-video-api/";
export const API_VIDEO_COURSE_UPDATE_PERCENT_TRACKING_VIDEO = URL_SITE + "update-percent-track-view-video-api/";
// end laulv



/** FOCUS ZONE */
export const API_FOCUS_ZONE = URL_SITE + "focus-zone/";
export const API_FOCUS_ZONE_LIST = API_FOCUS_ZONE;
export const API_FOCUS_ZONE_LIBRARY_LIST = API_FOCUS_ZONE + 'library/';
export const API_FOCUS_ZONE_VIDEO_LIST = API_FOCUS_ZONE + 'video/';
export const API_FOCUS_ZONE_VIDEO_LIBRARY_LIST = API_FOCUS_ZONE + 'video-library/';