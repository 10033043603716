export const NO_PERMISSION_URL = `/403`;
export const NOT_FOUND_URL = `/404`;

export const LOGIN_URL = `/login`;
export const FORGOT_PASSWORD_URL = `/forgot-password`;

export const DASHBOARD_URL = `/dashboard`;

export const MY_ACCOUNT_URL = `/my-account`;
export const PROFILE_URL = MY_ACCOUNT_URL + `/profile`;
export const CHANGE_PASSWORD_URL = MY_ACCOUNT_URL + `/change-password`;
export const PAYMENT_HISTORY_URL = MY_ACCOUNT_URL + `/payment-history`;

export const PAYMENT_OPTIONS_URL = MY_ACCOUNT_URL + `/payment-options`;
export const PAYMENT_OPTIONS_QUERY_PARAMS_KEY = {
  'ACTIVE': 'active', // default active membership tab (0: monthly subscription (default if none), 1: On Demand Lessons)
  'SESSION_ID': 'session_id', // Stripe return session_id
  'TYPE': 'type', // Stripe return type (success, cancel)
};

export const BILLING_PAYMENT_URL = MY_ACCOUNT_URL + `/billing-and-payment`;

export const VIDEO_CATEGORIES_URL = `/videos`;
export const VIDEO_DETAIL_URL = `/video`;

export const MY_STUDENT_URL = `/my-students`;
export const MY_STUDENT_SWINGS_URL = `${MY_STUDENT_URL}/swings`;
export const MY_STUDENT__ALL_SWINGS_URL = `${MY_STUDENT_URL}/all-swings`;
export const MY_STUDENT__ALL_STUDENTS_URL = `${MY_STUDENT_URL}/all-students`;

export const LESSON_MANAGE_URL = `/lesson-manage`;
export const LESSON_MANAGE_QUERY_PARAMS_KEY = {
  SHOW_ADD_MODAL: 'show-add-modal', // open add modal default
};
export const LESSON_MANAGE_ADD_URL = LESSON_MANAGE_URL + `/add`;


export const LIBRARY_URL = `/library`;
export const IMAGES_LIBRARY_URL = LIBRARY_URL + `/images-library`;
export const VIDEOS_LIBRARY_URL = LIBRARY_URL + `/video-library`;
export const EXERCISE_LIBRARY_URL = LIBRARY_URL + `/exercise-library`;
export const SEVEN_STAGE_SWING_LIBRARY_URL = LIBRARY_URL + `/seven-stage-swing-library`;

export const LESSON_POST_URL = `/lesson-post`;

export const GOLF_SWINGS_URL = `/golf-swings`;
export const GOLF_SWING_ADD_URL = GOLF_SWINGS_URL + `/add`;

export const REPORTS_URL = `/reports`;
export const REPORTS__NUMBER_OF_LESSON_GIVEN_URL = `${REPORTS_URL}/number-of-lessons-given`;
export const REPORTS__REVENUA_BROKEN_DOWN_BY_PRODUCT_URL = `${REPORTS_URL}/revenue-broken-down-by-product`;
export const REPORTS__USERS_CANCELLED_SUBSCRIPTION_URL = `${REPORTS_URL}/users-cancelled-subscription`;
export const REPORTS__AT_RISK_REPORT_URL = `${REPORTS_URL}/at-risk-report`;
export const REPORTS__PAYMENT_LOGS_URL = `${REPORTS_URL}/payment-logs`;
export const REPORTS__MEMBERS_URL = `${REPORTS_URL}/members`;
export const REPORTS__PAGEVIEWS_URL = `${REPORTS_URL}/pageviews`;

// laulv
export const VIDEO_COURSE_CATEGORIES_URL = `/video-courses`; 
export const VIDEO_COURSE_CATEGORIES_DETAIL_URL = `/courses`;
export const PAYMENT_HISTORY_VIDEO_COURSE_URL = `/videos-courses-payment-history`;
export const PAYMENT_HISTORY_FOR_COACH_URL = MY_ACCOUNT_URL + `/payment-history-coach`;
export const VIDEO_COURSE_CATEGORIES_DETAIL_1_URL = `/courses1`;
// end laulv

// ============================

export const MAIN_PAGE_URL = DASHBOARD_URL;
export const MAIN_WEBSITE_URL = 'https://members.golftec.com.au/'; //main website
